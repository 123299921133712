import { APIConstants } from 'config';
import axiosInstance from 'utils/http-interceptor';
import type { ErrorResponseType } from 'types/error-response';

export const getZplByOs = async (
  os: string,
  size: '10X10' | '10X15'
): Promise<string> => {
  try {
    const { status, data } = await axiosInstance.post<
      string | ErrorResponseType
    >(`/${APIConstants.emissions}/zebra/get-zpl`, {
      os,
      size,
    });

    if (status === 200) {
      return data as string;
    }

    return Promise.reject(
      new Error((data as ErrorResponseType).payload?.error)
    );
  } catch (error: any) {
    return error;
  }
};
