/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useAtom } from 'jotai';

import { useAuth } from 'contexts/auth-context';
import { useGTM } from 'hooks/use-gtm';
import UnauthenticatedApp from './unauthenticated-app';
import AuthenticatedApp from './authenticated-app';
import { handleLoginResponse } from 'api/auth';
import { sendEvent } from 'utils/gtm';
import { loginIsSuccessAtom } from 'atoms/login';
import axiosInstance from './utils/http-interceptor';

import type {
  LoginResponseOk,
  RefreshTokenResponse,
  UnionLoginResponse,
  LoginResponseNok,
  LoginResponseReject,
  SocialLoginResponseDataType,
} from 'types/auth';
// UNIVERSAL

import withUniversalSession, {
  useSession,
} from '@blue-express/bx-lib-universal-frontend';

const App = (): JSX.Element => {
  const { getSession } = useSession();
  const session = getSession();
  const [, setIsSuccess] = useAtom(loginIsSuccessAtom);
  const [authorizationOk, setAuthorizationOk] = useState(false);

  useEffect(() => {
    if (session) {
      if (session.ssoToken) {
        setAuthorizationOk(true);
        const data_response = {
          access_token: session.ssoToken.split(' ')[1],
          expires_in: 600,
          refresh_expires_in: 1800,
          refresh_token: session.ssoRefresh,
          token_type: 'bearer',
          id_token: session.idToken,
          session_state: session.sessionid,
          scope: 'openid email profile',
        };
        handleLoginResponse(data_response as LoginResponseOk);
        sendEvent({
          event: 'login',
          method: 'email',
        });
        setIsSuccess(true);
        axiosInstance.defaults.headers.common['Authorization'] =
          session.ssoToken;
      }
    }
  }, [session]);
  return (
    <HelmetProvider>
      {authorizationOk ? <AuthenticatedApp /> : <>Olá</>};
    </HelmetProvider>
  );
};

export default withUniversalSession(App as any, {
  env: 'dev',
  service: 'agencias',
  storage: 'localStorage',
  locale: 'es',
});

// import { FC } from 'react';
// import { HelmetProvider } from 'react-helmet-async';

// import { useAuth } from 'contexts/auth-context';
// import { useGTM } from 'hooks/use-gtm';
// import UnauthenticatedApp from './unauthenticated-app';
// import AuthenticatedApp from './authenticated-app';

// const App: FC = () => {
//   useGTM();
//   const { user } = useAuth();

//   return (
//     <HelmetProvider>
//       {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
//     </HelmetProvider>
//   );
// };

// export default App;
